/*global styling file*/

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

.bg-linear-default {
  background: #ffffff;
}

.slick-dots {
  bottom: -10px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 15px;
}

.slick-dots li {
  width: 65px;
  height: 6px;
}

.slick-dots li button {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0;
  background: #e6e5e4;
}

.slick-dots li.slick-active button {
  background-color: #6ec8e2;
}

.slick-dots li button:before {
  display: none;
}
